::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: #5b5b5b;
  border-radius: 10px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  background-color: rgb(17 24 39);
}

body {
  background-color: rgb(17 24 39 / 15%);
  color: #d3d3d3;

  --dark-bg: #34495e;
  --light-bg: #ecf0f1;
  --transition: all 0.5s ease;
  --dark-trail: #2c3e50;
  --light-trail: #16a085;
  --icon-color: var(--dark-trail);
}

button,
input,
select {
  outline: none;
}

.page:before {
  content: '';
  position: fixed;
  z-index: -1;
  width: 100%;
  height: 100%;
  opacity: 0.1;
  background-image: url('./../img/game.png');
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;
}

* {
  box-sizing: border-box;
  color: #d3d3d3;
}

@media only screen and (min-width: 600px) {
  .wrapper {
    width: 100% !important;
    margin: 0 auto;
  }
}

@media only screen and (min-width: 768px) {
  .wrapper {
    width: 100% !important;
    margin: 0 auto;
  }
}

@media only screen and (min-width: 992px) {
  .wrapper {
    width: 100% !important;
    margin: 0 auto;
  }
}

@media only screen and (min-width: 1200px) {
  .wrapper {
    width: 100% !important;
    margin: 0 auto;
  }
}

@keyframes blink {
  70% {
    opacity: 0.7;
  }
}
@keyframes fade {
  0% {
    opacity: 1;
  }
  2% {
    opacity: 0.1;
  }
  3% {
    opacity: 1;
  }
  5% {
    opacity: 0.1;
  }
  6% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
@keyframes move-content {
  0% {
    opacity: 0.1;
    /*margin-top: 10px;*/
  }
  100% {
    opacity: 1;
    /*margin-top: 0px;*/
  }
}

.flex {
  display: flex;
  justify-content: space-between;
}

.button {
  font-size: 25px;
  background: transparent;
  outline: none;
  padding: 2px 10px;
  border: none;
  border-radius: 10px;
  box-shadow: 0 0 3px 0 black;
  transition: .1s box-shadow ease;
}

.text-field {
  display: inline-block;
  font-size: 22px;
  min-width: 120px;
  height: 40px;
  border-radius: 10px;
  padding: 5px 10px;
  box-shadow: inset 2px 1px 3px rgba(255, 255, 255, 0.1), inset 1px 2px 6px rgba(0, 0, 0, 1);
  background: transparent;
  border: none;
}

.button:hover {
  box-shadow: 0 0 7px 0 black;
}

.loading-global,
.config-error {
  position: fixed;
  width: 100%;
  height: 100%;
  text-align: center;
  padding-top: 15%;
}

.center {
  text-align: center;
}

.loading-global-text {
  font-size: 40px;
  text-shadow: 2px 2px 1px rgba(0,0,0,0.3), 0 0px 15px #fff, 0 0 10px #38eeff, 0 0 50px #38eeff;
}

.config-error-text {
  font-size: 40px;
  text-shadow: 2px 2px 1px rgba(255, 20, 20, 0.3), 0 0px 15px #ff0000, 0 0 10px #ff16f0, 0 0 50px #ff00b7;
}

.coming-soon {
  height: 300px;
  padding-top: 120px;
  text-align: center;
}

.coming-soon-text {
  font-size: 40px;
  text-shadow: 2px 2px 1px rgba(0,0,0,0.3), 0 0px 15px #fff, 0 0 10px #38eeff, 0 0 50px #38eeff;
}

.authors-text {
  text-shadow: 2px 2px 1px rgba(183, 255, 92, 0.3), 0 0px 15px #10dc00, 0 0 10px #00fddc, 0 0 50px #82ff65;
}

.authors-text-fade-1 {
  animation: fade 1s infinite alternate;
}

.authors-text-fade-2 {
  animation: fade .7s infinite alternate;
}

.authors-text-fade-3 {
  animation: fade .5s infinite alternate;
}

.authors-text-fade-4 {
  animation: fade 1.1s infinite alternate;
}

.loading-global-text span:nth-child(1),
.config-error-text span:nth-child(1),
.coming-soon-text span:nth-child(1) {
  animation: fade .3s infinite alternate;
}

.loading-global-text span:nth-child(2),
.config-error-text span:nth-child(2),
.coming-soon-text span:nth-child(2) {
  animation: fade .1s infinite alternate;
}

.loading-global-text span:nth-child(3),
.config-error-text span:nth-child(3),
.coming-soon-text span:nth-child(3) {
  animation: fade .5s infinite alternate;
}

.header {
  width: 100%;
  height: var(--header-height);
  padding: 25px 10px;
}

.header-content {
  text-align: center;
}

.logo {
  display: inline-block;
  transform: rotate(-2deg);
  font-size: 27px;
  text-align: center;
  margin-left: 150px;
}

.logo-www {
  margin: 0 auto;
}

.logo-www,
.logo-b {
  color: #ccf;
}

.logo-b {
  text-shadow: 2px 2px 1px rgba(0,0,0,0.5), 0 0 20px #fff, 0 0 10px #7d26cd, 0 0 50px #7d26cd;
}

.logo-www {
  text-shadow: 2px 2px 1px rgba(0,0,0,0.3), 0 0px 15px #fff, 0 0 10px #38eeff, 0 0 50px #38eeff;
}

.logo-b {
  opacity: 1;
}

.logo:hover .logo-www {
  text-shadow: 2px 2px 1px rgba(0,0,0,0.3), 0 0px 15px #fff, 0 0 10px #38eeff, 0 0 50px #38eeff;
}

.logo:hover .logo-b {
  animation: fade 2s infinite alternate;
}

.menu {
  position: fixed;
  left: 0;
  top: 0;
  width: 150px;
  padding: 100px 20px 0px 20px;
  height: 100%;
}

.menu-list {
  padding: 0;
  margin: 0;
}

.menu-list li {
  list-style: none;
  margin-bottom: 20px;
  font-size: 20px;
}

.menu-list li a {
  text-decoration: none;
  padding: 5px 10px;
  color: #d3d3d3;
}

.menu-list li a.active {
  color: white;
}

.content {
  padding: 10px 10px 10px 150px;
  animation: move-content .3s;
}

.block {
  display: inline-block;
  background-color: #353a4b96;
  box-shadow: 0px 6px 20px -14px #00fbff69;
  height: fit-content;
  padding: 10px 15px 20px 15px;
  margin: 10px;
  border-radius: 10px;
}

.buy-sell-choose {
  font-size: 30px;
}

.radio-neon-wrapper {
  display: inline-block;
}

.radio-neon-wrapper label {
  position: relative;
  display: inline-block;
}
.radio-neon-wrapper label input {
  appearance: none;
  -webkit-appearance: none;
  cursor: pointer;
}

.radio-neon-wrapper label .radio-neon {
  position: relative;
  display: inline-block;
  user-select: none;
  width: 60px;
  height: 40px;
  background: #091921;
  line-height: 40px;
  text-align: center;
  margin: 0 4px;
  color: #6f6f6f;
  font-size: 16px;
  border-radius: 10px;
  cursor: pointer;
}

.radio-neon-wrapper label input:checked ~ .radio-neon {
  color: #00fff1;
  box-shadow: inset -1px -1px 3px rgba(255, 255, 255, 0.1), inset 2px 2px 6px rgba(0, 0, 0, 1);
  text-shadow: 0 0 5px #00fff1, 0 0 20px #00fff1;
}

.form-switch {
  display: inline-block;
  cursor: pointer;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

.form-switch i {
  position: relative;
  display: inline-block;
  margin-right: .5rem;
  width: 45px;
  height: 20px;
  background-color: #9f9f9f;
  border-radius: 4px;
  vertical-align: text-bottom;
  transition: all 0.1s ease;
}

.form-switch i::before {
  content: "";
  position: absolute;
  left: 0;
  width: 42px;
  height: 16px;
  background-color: #9f9f9f;
  border-radius: 4px;
  transform: translate3d(2px, 2px, 0) scale3d(1, 1, 1);
  transition: all 0.1s ease;
}

.form-switch i::after {
  content: "";
  position: absolute;
  left: 0;
  width: 21px;
  height: 15px;
  background-color: #dadada;
  border-radius: 4px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.24);
  transform: translate3d(2px, 2px, 0);
  transition: all 0.1s ease;
}

.form-switch:active i::after {
  width: 28px;
  transform: translate3d(2px, 2px, 0);
}

.form-switch:active input:checked + i::after {
  transform: translate3d(16px, 2px, 0);
}

.form-switch input {
  display: none;
}

.form-switch input:checked + i {
  background-color: #619d6b;
  box-shadow: 0 0 5px #619d6b;
}

.form-switch input:checked + i::before {
  transform: translate3d(18px, 2px, 0) scale3d(0, 0, 0);
}

.form-switch input:checked + i::after {
  transform: translate3d(22px, 2px, 0);
}

.form-switch-text {
  margin-right: 6px;
}

.toggle-filters {
  display: inline-block;
  vertical-align: top;
  width: 34px;
  height: 34px;
  text-align: left;
  font-size: 22px;
  margin-top: 3px;
  margin-left: 10px;
  border: none;
  border-radius: 4px;
  background-color: transparent;
}

.toggle-filters.active path {
  color: #619D6B;
  text-shadow: 0 0 5px #00fff1, 0 0 20px #00fff1;
}

.trade-total-wrapper {
  text-align: right;
}

.trade-total-gold-wrapper,
.trade-total-money-wrapper {
  margin-bottom: 10px;
}

.trade-total-gold-wrapper .item {
  margin-top: -1px;
  margin-right: 5px;
}

.trade-total-money-wrapper .dollar-sign {
  font-size: 30px;
  color: white;
  margin-right: 20px;
  text-shadow: 0 0 5px #00fff1, 0 0 20px #00fff1;
}

.trade-content {
  text-align: center;
}

.search-items {
  margin-top: 10px;
  text-align: center;
}

.search-input-wrapper {
  display: inline-block;
  position: relative;
}

.search-cross {
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 999;
  font-size: 28px;
  width: 30px;
  height: 30px;
  line-height: 1;
  text-align: center;
  cursor: pointer;
}

.search-input {
  display: inline-block;
  width: 215px;
}

.search-sort {
  display: block;
  width: 300px;
  margin: 10px auto 0px auto;
  cursor: pointer;
}

.search-sort:focus option {
  background-color: #282D3D;
}

ul.search-items-list {
  display: inline-block;
  text-align: left;
  background-color: #282e3f;
  width: 300px;
  border-radius: 10px;
  height: 200px;
  font-size: 18px;
  margin: 0;
  padding: 10px;
  box-shadow: 0px 1px 4px black;
  overflow-y: scroll;
}

ul.search-items-list li {
  list-style: none;
  cursor: pointer;
  border-radius: 5px;
  padding: 3px;
  margin-bottom: 5px;
  font-size: 15px;
}

ul.search-items-list li:hover {
  background-color: #1d212d;
}

.trade-content {
  margin-top: 40px;
  min-height: 200px;
}

.trade-items {
  text-align: center;
  margin-top: 10px;
}

.trade-item {
  margin-bottom: 10px;
}

.trade-item-remove {
  display: inline-block;
  font-size: 24px;
  margin-left: 15px;
  cursor: pointer;
}

.trade-item .text-field {
  width: 160px;
}

.share-button-wrapper {
  margin-top: 20px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  position: relative;
  background-color: #595959;
  padding: 20px;
  margin-top: -30%;
  border-radius: 5px;
  max-width: 500px;
  width: 90%;
  z-index: 1001;
}

.modal-header {
  position: absolute;
  right: 15px;
  top: 15px;
}

.modal-close-button {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.modal-body {

}

.question-button {
  background: transparent;
  border: none;
  width: 19px;
  height: 19px;
  display: inline-block;
  vertical-align: middle;
  font-size: 13px;
  border-radius: 5px;
  box-shadow: 0 0 3px black;
  box-sizing: border-box;
  min-width: 19px;
  min-height: 20px;
}

.modal-h2 {
  margin-top: -5px;
}

ul.how-to-list {
  padding-left: 20px;
  text-align: left;
}

.craft-items {
  text-align: center;
  position: relative;
}

.craft-item,
.gear-wrapper {
  display: inline-block;
  vertical-align: top;
  min-width: 175px;
  width: 210px;
  /*min-height: 230px;*/
  background-color: #191e3052;
  padding: 10px;
  text-align: left;
  border-radius: 10px;
  margin: 10px;
  position: relative;
}

.gear-wrapper {
  width: 250px;
  min-height: 390px;
  background-color: #191e3052;
  padding: 10px;
  text-align: left;
  border-radius: 10px;
  margin: 10px;
  position: relative;
}

.gear-sum {
  margin-top: 15px;
}

.gear-requirements {
  margin-top: 10px;
}

.gear-icons {
  text-align: center;
}

.favorite-button,
.gear-button {
  display: none;
  position: absolute;
  top: -8px;
  right: -12px;
  width: 25px;
  height: 25px;
  text-align: center;
  border-radius: 50%;
  cursor: pointer;
}

.favorite-button svg,
.gear-button svg {
  font-size: 20px;
}

.craft-item:hover .favorite-button,
.favorite-button.active,
.craft-item:hover .gear-button,
.gear-button.active {
  display: block;
}

.favorite-button path,
.gear-button path {
  color: gray;
}

.favorite-button.active path {
  color: red;
}

.gear-button.active path {
  color: #4cd964;
}

.gear-button {
  right: 18px;
}

.craft-item .item {
  margin-right: 2px;
}

.item-placeholder {
  box-shadow: 0 0 1px white;
}

.gear-icon {
  cursor: pointer;
  display: inline-block;
  margin: 5px;
}

.craft-sub-items,
.craft-item-seeds {
  margin-top: 15px;
}

.craft-sub-item {
  margin-bottom: 7px;
}

.craft-sub-item-deep {
  margin-left: 30px;
  position: relative;
}

.craft-sub-item-deep:before {
  content: '';
  position: absolute;
  left: -16px;
  top: -8px;
  width: 2px;
  height: 100%;
  background-color: #444444;
}

.craft-sub-item {
  position: relative;
}

.craft-sub-item-deep .craft-sub-item:before {
  content: '';
  width: 11px;
  height: 2px;
  background: #444444;
  left: -14px;
  top: 15px;
  position: absolute;
}

.craft-items-search {
  text-align: center;
  padding: 10px 0;
  margin-bottom: 10px;
}

.craft-items-wrapper {
  min-height: 100vh;
}

.craft-item-name {
  text-overflow: ellipsis;
  overflow: hidden;
  width: calc(100% - 60px);
  white-space: nowrap;
  display: inline-block;
  vertical-align: middle;
}

.craft-item-vendor-price {
  float: right;
  margin-top: 6px;
  color: #ffea79;
}

.item {
  display: inline-block;
  vertical-align: middle;
  width: 16px;
  height: 16px;
  background-image: url('./../img/sprite17.png');
  background-repeat: no-repeat;
  zoom: 2;
  image-rendering: pixelated;
  border-radius: 5px;
}

.item-small {
  zoom: 1;
}

.rarity-0 {

}

.rarity-1 {
  background-color: rgba(0, 128, 0, 0.2);
  box-shadow: 0 0 1px rgba(0, 128, 0, 0.5);
}

.rarity-2 {
  background-color: rgba(84, 84, 255, 0.2);
  box-shadow: 0 0 1px rgba(84, 84, 255, 0.5);
}

.rarity-3 {
  background-color: rgba(128, 0, 128, 0.2);
  box-shadow: 0 0 1px rgba(128, 0, 128, 0.5);
}

.rarity-4 {
  background-color: rgba(255, 215, 0, 0.2);
  box-shadow: 0 0 1px rgba(255, 215, 0, 0.5);
}

.craft-items-filters {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
  justify-content: center;
  margin-bottom: 15px;
}

.craft-item-filter-type label {
  cursor: pointer;
  user-select: none;
  transition: text-shadow 0.3s;
  padding: 2px 3px;
}

.craft-item-filter-type {
  border-radius: 5px;
  cursor: pointer;
}

.craft-item-filter-type input[type="checkbox"] {
  display: none;
}

.craft-item-filter-type input[type="checkbox"]:checked + label {
  color: white;
  text-shadow: 0 0 5px #60ff00, 0 0 20px #30ff00;
}

.craft-item-filters-additional {
  margin-bottom: 15px;
}

.craft-item-stats,
.craft-item-requirements,
.seed-properties {
  margin-top: 10px;
}

.craft-item-stat span {
  color: #00FF00;
}

.craft-item-requirement span {
  color: #FFA040;
}

.abilities {
  text-align: center;
}

.ability {
  text-align: center;
  display: inline-block;
  vertical-align: top;
  width: 400px;
  margin: 10px;
}

.ability-name {
  margin-bottom: 10px;
}

.ability-image-wrapper {
  margin-right: 10px;
}

.ability-demo {
  position: relative;
  height: 230px;
}

.ability-demo .coming-soon {
  padding: 0;
  width: 300px;
  height: 60px;
  position: absolute;
  top: calc(50% - 30px);
  left: calc(50% - 150px);
}

.ability-demo .coming-soon {
  font-size: 30px;
}

.ability video {
  width: 100%;
}

.version-text {
  font-size: 14px;
  position: absolute;
  bottom: 15px;
  left: 30px;
  color: #999999;
}

.about-text-wrapper {
  max-width: 500px;
  margin: 0 auto;
}

.items-count {
  position: absolute;
  right: 30px;
  top: 30px;
}

.seed-properties {
  width: 100%;
  margin: 10px 0;
}

.seed-properties table {
  width: 100%;
  border-collapse: collapse;
}

.seed-properties th,
.seed-properties td {
  border: 1px solid #545454;
  padding: 8px;
  text-align: left;
}

.seed-properties th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.seed-properties tr:nth-child(even) {
  background-color: rgba(100, 100, 100, 0.2);
}

.seed-properties tr:hover {
  background-color: rgba(100, 100, 100, 0.3);
}

.scroll-to-top {
  position: fixed;
  z-index: 999;
  bottom: 40px;
  right: 20px;
  padding: 10px;
  font-size: 12px;
  cursor: pointer;
  border-radius: 5px;
  background-color: rgba(65, 75, 103, 0.3);
  color: #bebec0;
  border: none;
  outline: none;
  transition: background-color 0.3s ease;
  width: 40px;
  height: 40px;
  text-align: center;
}

.scroll-to-top:hover {
  background-color: rgba(75, 85, 114, 0.6);
}



/* Firefox */
@-moz-document url-prefix() {
  .item {
    margin-right: 5px !important;
  }

  .craft-sub-item {
    margin-bottom: 20px !important;
    margin-top: 20px !important;
  }

  .craft-sub-item .item {
    margin-left: 0px !important;
    margin-right: 4px !important;
  }
}
/* /Firefox*/



/* Responsive */

.menu-mobile-button-wrapper {
  display: none;
  position: absolute;
  top: 20px;
  left: 20px;
}

.menu-mobile-button {
  background-color: transparent;
  border: none;
  color: white;
  font-size: 30px;
}

.menu-mobile-button-close {
  display: none;
  position: absolute;
  top: 20px;
  left: 20px;
  border: none;
  background: transparent;
  font-size: 30px;
}

@media only screen and (max-width: 767px) {
  .menu {
    display: none;
    width: 100%;
    text-align: center;
    z-index: 1000;
    background-color: #000000d9;
    padding: 100px 0px 0px 0px;
  }

  .menu a {
    font-size: 40px;
  }

  .menu.active,
  .menu.active .menu-mobile-button-close {
    display: block;
  }

  .menu-mobile-button-wrapper {
    display: block;
  }

  .content {
    padding: 10px 10px 10px 10px;
  }

  .logo {
    margin-left: 0px;
  }
}



@media only screen and (max-width: 480px) {
  .header {
    padding: 5px 0;
  }

  .menu-mobile-button-wrapper {
    top: 5px;
  }

  .items-count {
    top: 15px;
  }

  .block {
    margin: 0px;
  }

  .craft-items-filters-wrapper {
    overflow-x: scroll;
  }

  .craft-items-filters-wrapper::-webkit-scrollbar {
    height: 3px;
    display: none;
  }

  .craft-items-filters {
    width: max-content;
    margin-bottom: 10px;
  }

  .craft-item {
    width: 100%;
    margin: 10px 0;
  }

  .trade-items {
    margin-top: 30px;
  }

  .trade-content {
    padding-bottom: 60px;
  }

  .trade-total-block {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 999;
    width: 100% !important;
    padding: 10px;
    background: #333333;
  }

  .buy-sell-choose-block {
    width: 100% !important;
  }

  .trade-total-wrapper {
    text-align: center;
  }

  .ability {
    width: 270px;
  }

  .ability-demo {
    height: 160px;
  }
}

/* /Responsive */











